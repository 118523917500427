
.popover {
  max-width: 35% !important;
  .popover-title {
    font-size: 1.5em;
  }
  .popover-content {
    white-space: pre-wrap !important;
  }
}
